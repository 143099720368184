import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 园区公告 - 分页查询
 * @param params
 */
export function getParkNoticeList(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/notice/getNoticeByParam',
    method: 'get',
    params,
  });
}

/**
 * 园区公告 - 新增
 * @param params
 */
export function addParkNotice(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/notice/addNotice',
    method: 'post',
    data: params,
  });
}

/**
 * 园区公告 - 通过id获取公告详情
 * @param params
 */
export function getNoticeDetailById(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/notice/getDetails',
    method: 'get',
    params,
  });
}

/**
 * 园区公告 - 更新
 * @param params
 */
export function updateParkNotice(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/notice/editNotice',
    method: 'post',
    data: params,
  });
}

/**
 * 园区公告 - 删除
 * @param params
 */
export function deleteParkNotice(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/notice/deleteNotice',
    method: 'post',
    data: params,
  });
}
